import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Draggable, Droppable } from "@hello-pangea/dnd";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { LazyLoadImage } from "react-lazy-load-image-component";

import icDragDrop from "../../../../../assets/images/cms/icDragDrop.png";
import LoadingTable from "../../../configuration/components/Table/LoadingTable";
import EmptyTable from "../../../configuration/components/Table/EmptyTable";

import RightArrowIcon from "../../../../../components/svgs/RightArrowIcon";
import playlistApi from "../../../../../apis/api/playlist";
import { reqGetDetailPlaylists } from "../../../../../reduxs/cms/action";
import { getS3BEMediaUrl } from "../../../../../helper/media";
import { getSrc } from "../../../gallery/utils";
import { MEDIA_TYPE } from "../../../utils";
import { Button, Modal } from "../../../components/commons";
import EditMediaContentModal from "../../../media/edit-modal";
import defaultVideo from "../../../../../assets/images/cms/icVideo.png";
import defaultImage from "../../../../../assets/images/cms/icVideo.png";

// type Props = {
//     isLoadingTable: boolean;
//     mediaType:string;
//     medias: any[];
//     playListRef?: any;
// }

const MediasContent = (
  { isLoadingTable, medias, playlistId, mediaType },
  ref
) => {
  const dispatch = useDispatch();
  const [mediasState, setMediasState] = useState(medias);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(null);

  useEffect(() => {
    setMediasState(medias);
  }, [medias]);

  const onDragEnd = async (result) => {
    if (result && mediasState?.length > 1) {
      const indexDestination = result?.destination?.index;
      const indexSource = result?.source?.index;

      if (
        !result?.destination ||
        !result?.source ||
        indexDestination === indexSource
      )
        return;

      const newMedias = [...mediasState];
      const [reorderedItem] = newMedias.splice(indexSource, 1);
      newMedias.splice(indexDestination, 0, reorderedItem);
      setMediasState(newMedias);

      const playlistOverride = newMedias.map((item) => item.id);

      const res = await playlistApi.updateMediaPlaylist(playlistId, {
        medias: playlistOverride,
      });

      if (res?.data) {
        toast.success("Update playlist successfully");
      } else {
        toast.error("Update playlist failed");
      }
    }
  };

  //expose onDragEnd for parent component to trigger
  useImperativeHandle(ref, () => ({
    triggerDragEnd: onDragEnd,
  }));
  //--------

  const handleRemoveMedia = async (media, onRemoveSuccess) => {
    if (!media) return;

    setRemoveLoading(true);
    const res = await playlistApi.removeFilesFromPlayList(playlistId, {
      medias: [media.id],
    });

    if (res?.data) {
      toast.success("Remove media successfully!");

      onRemoveSuccess && onRemoveSuccess();
      dispatch(reqGetDetailPlaylists(playlistId));
    } else {
      toast.error("Remove media failed!");
    }

    setRemoveLoading(false);
  };

  return (
    <>
      {!isLoadingTable && (
        <Droppable droppableId="gallery-list">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {!!mediasState?.length &&
                mediasState?.map((v, index) => {
                  const src =
                    v?.type === MEDIA_TYPE.VIDEO
                      ? getS3BEMediaUrl(v?.thumbnailPath)
                      : getSrc(mediaType, v?.path);

                  return (
                    <Draggable key={v?.id} draggableId={v?.id} index={index}>
                      {(provided, snapshot) => (
                        <div
                          key={v?.id}
                          className={`flex items-center rounded gap-5 bg-[#e4ccfe] mb-2 px-2`}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div className="flex-1">
                            <div className="flex gap-5">
                              <div
                                onClick={() => {
                                  setIsEditModal(true);
                                  setSelectedMedia(v);
                                }}
                              >
                                <LazyLoadImage
                                  className="w-16 h-16 rounded-lg m-auto object-cover"
                                  alt={v?.name}
                                  src={src}
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src =
                                      v?.type === MEDIA_TYPE.VIDEO
                                        ? defaultVideo
                                        : defaultImage;
                                  }}
                                />
                              </div>
                              <div className="flex-1 min-w-0">
                                <div>{v?.type}</div>
                                <div className="break-words overflow-wrap-anywhere">
                                  {v?.name}
                                </div>
                              </div>
                            </div>
                          </div>
                          <Modal
                            maskClassName="bg-opacity-50"
                            zIndex="z-[9999]"
                            trigger={
                              <div className="flex gap-5 items-center">
                                <span className="underline text-sm">
                                  Remove
                                </span>
                                <RightArrowIcon />
                                <img
                                  className="w-20 cursor-pointer"
                                  src={icDragDrop}
                                  alt=""
                                />
                              </div>
                            }
                            content={({ setIsShow }) => (
                              <div>
                                <p className="text-center text-xl">
                                  Do you want to remove this media from playlist
                                  ?
                                </p>
                                <div className="flex gap-4 items-center mt-5">
                                  <Button
                                    disabled={removeLoading}
                                    variant="text"
                                    className="flex-1"
                                    onClick={() => setIsShow(false)}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    isLoading={removeLoading}
                                    className="flex-1 bg-red-500 hover:bg-red-400"
                                    onClick={() =>
                                      handleRemoveMedia(v, () =>
                                        setIsShow(false)
                                      )
                                    }
                                  >
                                    Confirm
                                  </Button>
                                </div>
                              </div>
                            )}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}

              {(!mediasState || !mediasState?.length) && (
                <div className="flex justify-center items-center h-full">
                  <EmptyTable />
                </div>
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      )}
      {isLoadingTable && (
        <div className="flex justify-center items-center h-full">
          <LoadingTable />
        </div>
      )}
      {isEditModal && (
        <EditMediaContentModal
          content={selectedMedia}
          mediaType={mediaType}
          setShow={setIsEditModal}
          show={isEditModal}
          thumbnailContent={selectedMedia?.thumbnailContent}
          onSaveSuccess={() => {
            dispatch(reqGetDetailPlaylists(playlistId));
            setIsEditModal(false);
          }}
          isFromMediaFolder={true}
          handleDelete={handleRemoveMedia}
          isFromAsset={false}
        />
      )}
    </>
  );
};

export default forwardRef(MediasContent);
