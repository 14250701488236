/** @format */

import React, {
  useMemo,
  useState,
  useRef,
  useEffect,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { toast } from "react-toastify";
import AddMeidaContentModal from "../../../media/add-modal";
import { getS3BEMediaUrl } from "../../../../../helper/media";
import useDebouncedSearch from "../../../../../hooks/useDebouncedSearch";
import playlistApi from "../../../../../apis/api/playlist";
import { reqGetDetailPlaylists } from "../../../../../reduxs/cms/action";
import { Droppable, Draggable } from "@hello-pangea/dnd";
import EmptyTable from "../../../configuration/components/Table/EmptyTable";
import Search from "../../../gallery/Search";
import { Button, Modal } from "../../../components/commons";
import { LeftArrowIcon } from "../../../../../components/svgs/icons";
import LoadingTable from "../../../configuration/components/Table/LoadingTable";
import { getUploadParams } from "../../../utils";
import { useDropzone } from "react-dropzone";
import defaultVideo from "../../../../../assets/images/cms/icVideo.png";
import defaultImage from "../../../../../assets/images/cms/icVideo.png";

const AssetContent = ({
  mediaType,
  playlistId,
  handleRefreshMedias,
  medias,
}) => {
  const dispatch = useDispatch();
  const [createLoading, setCreateLoading] = useState(false);
  const [isShowAddContentModal, setIsShowAddContentModal] = useState(false);
  const [loadingCreateMedia, setLoadingCreateMedia] = useState(false);

  const refSearch = useRef();

  const { media } = useSelector((state) => state.cms);

  const [search, setSearch] = useDebouncedSearch();

  const onDrop = useCallback(
    async (files) => {
      if (!!files?.length) {
        setLoadingCreateMedia(true);
        const params = await getUploadParams(files);

        if (params?.length) {
          let completed = 0;

          await Promise.all(
            params.map(async (param) => {
              await playlistApi.createMediaPlaylist({ ...param, playlistId });
              completed++;
            })
          );
          toast.success("Add new content successfully!");
          handleRefreshMedias();
        }
        setLoadingCreateMedia(false);
      }
    },
    [mediaType]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
  });

  const newMedia = useMemo(() => {
    return !search
      ? media
      : media?.filter((media) =>
          media?.name?.toLowerCase()?.includes(search?.toLowerCase())
        ) || [];
  }, [media, search]);

  useEffect(() => {
    refSearch.current?.resetSearch();
  }, [playlistId]);

  const handleAddMedia = async (media, onCreateSuccess) => {
    setCreateLoading(true);
    if (playlistId) {
      const res = await playlistApi.addFilesToPlayList(playlistId, {
        medias: [media.id],
      });

      if (res?.data) {
        toast.success("Add new media successfully!");
        onCreateSuccess && onCreateSuccess();
        dispatch(reqGetDetailPlaylists(playlistId));
      } else {
        toast.error("Add media failed!");
      }
    } else {
      toast.warn("Please select playlist first");
    }

    setCreateLoading(false);
  };

  const onCreateSuccess = (item) => {
    handleRefreshMedias();
    setIsShowAddContentModal(false);
  };

  return (
    <section className="h-full flex flex-col">
      <div className="flex gap-2 items-center flex-row justify-end mb-1">
        <div
          className="cursor-pointer"
          onClick={() => mediaType && setIsShowAddContentModal(true)}
        >
          <p className="underline text-sm font-semibold">Add</p>
        </div>
      </div>
      <Search
        callbackOnInputChange={setSearch}
        formClassName="w-full"
        disabled={!media?.length}
        ref={refSearch}
      />
      <div className="h-2 w-full bg-[rgb(227,227,227)] my-5" />
      <div
        className="h-[68vh] overflow-y-scroll overflow-x-hidden"
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <Droppable droppableId="asset-content">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="grid grid-cols-2 md:grid-cols-3 gap-2"
            >
              {!!newMedia?.length &&
                newMedia?.map((item, index) => {
                  const isDisabledAdd = medias?.some(
                    (media) => media?.id === item?.id
                  );

                  return (
                    <Draggable
                      key={item?.id + `_${index}`}
                      draggableId={item?.id + `_${index}`}
                      index={index}
                      isDragDisabled={isDisabledAdd}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          key={item.id}
                          className="rounded-[10px]"
                        >
                          <LazyLoadImage
                            src={getS3BEMediaUrl(
                              item?.type === "videos"
                                ? item?.thumbnailPath
                                : item?.path
                            )}
                            className="h-[120px] object-cover w-full rounded-tl-lg rounded-tr-lg m-auto"
                            controls
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src =
                                item?.type === "videos"
                                  ? defaultVideo
                                  : defaultImage;
                            }}
                          />

                          <div className="bg-black p-2 text-white text-xs min-h-[48px]">
                            <p className="line-clamp-2 overflow-hidden">
                              {item?.name}
                            </p>
                          </div>
                          <Modal
                            disabled={isDisabledAdd}
                            maskClassName="bg-opacity-50"
                            zIndex="z-[9999]"
                            triggerClassName="h-auto"
                            trigger={
                              <Button
                                disabled={isDisabledAdd}
                                className="rounded-bl-2xl rounded-br-2xl w-full rounded-none flex cursor-pointer justify-start items-center gap-2 px-2  bg-[rgb(117,117,117)]"
                              >
                                <LeftArrowIcon
                                  fill="white"
                                  className="size-4"
                                />
                                <span className="text-[14px] text-white">
                                  Add
                                </span>
                              </Button>
                            }
                            content={({ setIsShow }) => (
                              <div>
                                <p className="text-center text-xl">
                                  Do you want to add this media to playlist ?
                                </p>
                                <div className="flex gap-4 items-center mt-5">
                                  <Button
                                    disabled={createLoading}
                                    variant="text"
                                    className="flex-1"
                                    onClick={() => setIsShow(false)}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    isLoading={createLoading}
                                    className="flex-1"
                                    onClick={() =>
                                      handleAddMedia(item, () =>
                                        setIsShow(false)
                                      )
                                    }
                                  >
                                    Save
                                  </Button>
                                </div>
                              </div>
                            )}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              {!newMedia?.length && (
                <div className="col-span-2 md:col-span-3">
                  <EmptyTable />
                </div>
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        {isDragActive && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-200 bg-opacity-70">
            <p className="text-2xl text-black font-bold">
              Drop the files here ...
            </p>
          </div>
        )}
        {loadingCreateMedia && (
          <div className="absolute inset-0 z-10 flex items-center justify-center bg-gray-200 bg-opacity-70">
            <LoadingTable />
          </div>
        )}
      </div>
      {isShowAddContentModal && (
        <AddMeidaContentModal
          mediaType={mediaType}
          show={isShowAddContentModal}
          setShow={setIsShowAddContentModal}
          onCreateSuccess={onCreateSuccess}
          isFromMediaFolder={true}
        />
      )}
    </section>
  );
};

export default AssetContent;
