import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { reqGetGallery } from "../../../../reduxs/gallery/action";
import UpsertGalleryPlaylist from "../upsertGalleryPlaylist";
import gallery from "../../../../apis/api/gallery";
import { toast } from "react-toastify";

const GalleryTypeB = ({ typeBEntity }) => {
  const dispatch = useDispatch();
  const { gallery: galleryRes } = useSelector((state) => state.gallery);
  const playlistId = galleryRes?.playlist?.id;

  const getGallery = async (id) => {
    await dispatch(reqGetGallery(id));
  };

  useEffect(() => {
    typeBEntity?.galleryId && getGallery(typeBEntity?.galleryId);
  }, [typeBEntity]);

  const onChangePlaylist = async (option) => {
    const { id } = option;

    if (id === playlistId) return;

    const isUpdate = playlistId || Boolean(galleryRes);
    const action = isUpdate ? "update" : "create";
    const method = isUpdate
      ? "updatePlaylistForGallery"
      : "createPlaylistForGallery";

    const res = await gallery[method]({
      [isUpdate ? "galleryId" : "id"]: typeBEntity?.galleryId,
      playlistId: id,
    });

    if (res?.data) {
      await dispatch(reqGetGallery(typeBEntity?.galleryId));
      toast.success(
        `${
          action.charAt(0).toUpperCase() + action.slice(1)
        } playlist successfully`
      );
    } else {
      toast.error(
        `${action.charAt(0).toUpperCase() + action.slice(1)} playlist failed`
      );
    }
  };

  return (
    <div>
      <div className="bg-white rounded-lg py-2 px-4">
        <div className="h-[calc(100vh-180px)] overflow-hidden">
          <UpsertGalleryPlaylist
            mediaType={typeBEntity?.type}
            gallery={typeBEntity}
            playlistId={galleryRes?.playlist?.id}
            callbackOnchangePlaylist={onChangePlaylist}
          />
        </div>
      </div>
    </div>
  );
};

export default GalleryTypeB;
