import { toast } from "react-toastify";
import mediaAPI from "../../apis/api/media";
import _ from "lodash";

export const MEDIA_TYPE = {
  IMAGE: "images",
  VIDEO: "videos",
  FLOORPLANS: "floorplans",
  VIRTUALTOURS: "virtualTours",
  PARONAMIC: "paronamics",
  MODELS: "models",
  VIEWLINES: "viewlines",
  "360IMAGE": "360image",
};

const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50MB
const UPLOAD_TIMEOUT = 20000; // 20 seconds

export const VIDEO_TYPE = [".mp4", ".avi", ".mov", ".webm", ".quicktime"];
export const IMAGE_TYPE = [".jpg", ".jpeg", ".png", ".gif", ".webp"];

export const getUploadParams = async (files, mediaType) => {
  if (files.length < 1) return;

  const acceptType = mediaType === MEDIA_TYPE.VIDEO ? VIDEO_TYPE : IMAGE_TYPE;
  const allAcceptedTypes = [...VIDEO_TYPE, ...IMAGE_TYPE];

  const isAcceptedTypes = files?.map((file) => {
    const fileType = file?.type?.split("/")[1];

    if (!fileType) return false;

    if (mediaType) {
      return acceptType.includes(`.${fileType}`);
    } else {
      return allAcceptedTypes.includes(`.${fileType}`);
    }
  });

  if (_.includes(isAcceptedTypes, false)) {
    toast.warn("Invalid file type selected");
    return [];
  }
  let results = [];

  try {
    for (let file of files) {
      if (file.size > MAX_FILE_SIZE) {
        toast.error(`File ${file.name} is too large. Maximum size is 50MB`);
        return [];
      }

      const formData = new FormData();
      formData.append("file", file);

      const uploadPromise = mediaAPI.uploadMedia(formData);
      const timeoutPromise = new Promise((_, reject) =>
        setTimeout(() => reject(new Error("Upload timeout")), UPLOAD_TIMEOUT)
      );

      try {
        const uploaded = await Promise.race([uploadPromise, timeoutPromise]);

        const type =
          file?.type?.split("/")[0] === "image"
            ? MEDIA_TYPE.IMAGE
            : MEDIA_TYPE.VIDEO;

        if (uploaded?.data) {
          results.push({
            isActive: true,
            path: uploaded.data.path,
            thumbnailPath:
              uploaded.data.thumbnailPath || "/1727663621731_IMG_2551.webp",
            type: mediaType || type,
            name: file.name.split(".").slice(0, -1).join("."),
            fileName: file.name,
          });
        } else {
          throw new Error(`Error uploading file: ${file.name}`);
        }
      } catch (error) {
        if (error.message === "Upload timeout") {
          toast.warn(`Upload timeout for file: ${file.name}`);
        } else {
          toast.warn(`Error uploading file: ${file.name}`);
        }
        return [];
      }
    }
  } catch (e) {
    toast.warn("An unexpected error occurred during upload");
    return [];
  }

  return results;
};

export const MODEL_MEDIA_TYPES = {
  MAIN: "main",
  HELPERS: "helpers",
};
