import React, { forwardRef, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import SelectField from "../../../configuration/components/selectField";
import MediasContent from "./MediasContent";
import Search from "../../Search";
import useDebouncedSearch from "../../../../../hooks/useDebouncedSearch";
import { reqGetGallery } from "../../../../../reduxs/gallery/action";
import { sortMediasByPlaylistOverride } from "../../utils";

//Props ={
// gallery?: {
//  galleryId: string;
// };
// playlistId: string;
// forUnit?: boolean;
// unitId?: string;
// mediaType?: string;
// ref?: any;
// }

const PlayListContent = (
  {
    gallery,
    unitName,
    playlistId,
    callbackOnchangePlaylist,
    forUnit,
    unitId,
    mediaType,
    override,
  },
  ref
) => {
  const dispatch = useDispatch();

  const playlists = useSelector((state) => state.cms.playlist);
  const { gallery: galleryRes, galleryLoading } = useSelector(
    (state) => state.gallery
  );
  const refSearch = useRef();

  const { control, reset } = useForm();
  const [search, setSearch, loading] = useDebouncedSearch();

  const isLoadingTable = galleryLoading || loading;

  const getGallery = async (id) => {
    await dispatch(reqGetGallery(id));
  };

  const options = useMemo(
    () => playlists?.data?.map((it) => ({ id: it.id, name: it.name })),
    [playlists]
  );

  const mediaSources = useMemo(
    () =>
      forUnit
        ? playlists?.data?.find((p) => p.id === playlistId)?.medias || []
        : galleryRes?.playlist?.medias || [],
    [forUnit, playlists, playlistId, galleryRes]
  );
  const medias = useMemo(() => {
    const newMedias = !search
      ? mediaSources
      : mediaSources?.filter((media) =>
          media?.name?.toLowerCase()?.includes(search?.toLowerCase())
        ) || [];
    const filterMedias = newMedias?.filter(
      (media) => media?.type === mediaType
    );

    return sortMediasByPlaylistOverride(
      filterMedias,
      galleryRes?.playlistOverride
    );
  }, [galleryRes, search, mediaSources]);

  useEffect(() => {
    reset({
      playlist: options?.find((it) => it?.id === playlistId) || null,
    });
  }, [options, playlistId]);

  useEffect(() => {
    gallery?.galleryId && getGallery(gallery.galleryId);
    refSearch.current?.resetSearch();
  }, [gallery]);

  return (
    <section className="h-full flex flex-col">
      <div className="flex flex-row mt-1 mb-2 items-center gap-1">
        <h2 className="inline-block w-[200px] capitalize">{`Linked playlist:`}</h2>
        <SelectField
          options={options || []}
          controller={{
            control,
            name: "playlist",
          }}
          placeholder="Select playlist"
          callbackOnChange={callbackOnchangePlaylist}
        />
      </div>
      <Search
        ref={refSearch}
        callbackOnInputChange={setSearch}
        formClassName="w-full"
        disabled={!medias?.length}
      />
      <div className="h-2 w-full bg-[rgb(227,227,227)] my-5" />
      <div className="content overflow-y-scroll overflow-x-hidden h-[68vh]">
        <MediasContent
          override={override}
          mediaType={mediaType}
          unitId={unitId}
          forUnit={forUnit}
          gallery={galleryRes}
          medias={medias}
          isLoadingTable={isLoadingTable}
          playlistId={playlistId}
          ref={ref}
        />
      </div>
    </section>
  );
};

export default forwardRef(PlayListContent);
