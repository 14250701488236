import React, { useEffect, useState } from "react";
import { getS3BEMediaUrl } from "../../../helper/media";
import PopupGallery from "../../media/popup-gallery";
import { handleSortOrder } from "../../../helper/utils";
import galleryApi from "../../../apis/api/gallery";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../../constants/options";
import socket from "../../../helper/socket";
import { LazyLoadImage } from "react-lazy-load-image-component";

export const Interiors = ({ isPresentation, item }) => {
  const [data, setData] = useState([]);

  const [showGallery, setShowGallery] = useState(null);

  const loadData = async () => {
    try {
      const res = await galleryApi.getGalleryDetail(item?.id);
      if (res && res.data) {
        const medias = handleSortOrder(
          res.data?.playlist?.medias,
          res.data?.playlistOverride
        );
        setData(medias);
      }
    } catch (e) {
      console.log("error get Interiors");
    }
  };

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.SHOW_GALLERY) {
      handleShowGallery(content.data.idx);
    }
    if (content.action === ACTION_NAME.CLOSE_GALLERY) {
      handleCloseGallery();
    }
  };
  useEffect(() => {
    loadData();

    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation, item?.id]);

  const handleShowGallery = (idx) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_GALLERY, {
        idx,
      });
    }
    setShowGallery(idx);
  };

  const handleCloseGallery = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_GALLERY);
    }
    setShowGallery(false);
  };

  const getCssMediaItem = (position) => {
    if (position === 0) {
      return "h-[496px] w-full flex-[1_0_0]";
    }
    if (position === 1) {
      return "w-[351px] h-[496px]";
    }
    if (position === 2) {
      return "w-full h-[718.914px]";
    }
  };

  return (
    <div className="interiors mx-[108px] my-[100px]">
      <div className="flex flex-col items-center gap-[5px]">
        {data?.map((item, index) => {
          const position = index % 3;
          switch (position) {
            case 1:
              return null;
            case 0:
              return (
                <div
                  className={
                    "flex flex-row gap-[5px] justify-center items-center w-[1150px]"
                  }
                  key={index}
                >
                  <div
                    key={item.id}
                    onClick={() => handleShowGallery(item?.path)}
                    className={getCssMediaItem(position)}
                  >
                    <LazyLoadImage
                      className="h-full object-cover w-full cursor-pointer"
                      alt={item?.name}
                      src={getS3BEMediaUrl(item?.path)}
                      onError={(e) => {
                        e.currentTarget.src = getS3BEMediaUrl(item?.path);
                      }}
                    />
                  </div>
                  {data[index + 1] && (
                    <div
                      key={data[index + 1].id}
                      onClick={() => handleShowGallery(data[index + 1]?.path)}
                      className={getCssMediaItem(position)}
                    >
                      <LazyLoadImage
                        className="h-full object-cover w-full cursor-pointer"
                        alt={data[index + 1]?.name}
                        src={getS3BEMediaUrl(data[index + 1]?.path)}
                      />
                    </div>
                  )}
                </div>
              );
            case 2:
              return (
                <div
                  className={
                    "flex flex-row gap-[5px] justify-center items-center w-[1150px]"
                  }
                  key={index}
                >
                  <div
                    key={item.id}
                    onClick={() => handleShowGallery(item?.path)}
                    className={getCssMediaItem(position)}
                  >
                    <LazyLoadImage
                      className="w-full h-full object-cover"
                      alt={item?.name}
                      src={getS3BEMediaUrl(item?.path)}
                    />
                  </div>
                </div>
              );
            default:
              return null;
          }
        })}
      </div>
      <PopupGallery
        isPresentation={isPresentation}
        show={showGallery}
        listImage={data}
        onClose={handleCloseGallery}
      />
    </div>
  );
};
