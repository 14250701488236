import React, { useRef } from "react";
import { getS3BEMediaUrl } from "../../../helper/media";
import { ReactComponent as Close } from "../../../assets/images/close.svg";
import socket from "../../../helper/socket";

const PlayVideo = ({ urlBg, urlVideo, onStopVideo, isPresentation, setShowVideo }) => {
  const videoRef = useRef(null);

  const onSkipVideo = () => {
    onStopVideo();
    setShowVideo(false);
    if (!isPresentation) {
      const messageId = Date.now();
      socket.shareMedia({ FG: { FG_0000: "MOV", MSG_ID: messageId } });
    }
  };

  return (
    <div className="flex justify-center items-center fixed z-[99] h-full w-full top-0 left-0 bg-black bg-opacity-70">
      <div className="h-[50%] w-[50%] flex items-center">
        <video
          loop
          ref={videoRef}
          autoPlay={true}
          muted={false}
          preload="auto"
          id="intro-video-2"
        >
          <source src={getS3BEMediaUrl(urlVideo)} type="video/mp4" />
        </video>
        <div className="fixed cursor-pointer right-[45px] top-[80px]" onClick={onSkipVideo}>
          <Close fill="white" />
        </div>
      </div>
    </div>
  );
};

export default PlayVideo;
