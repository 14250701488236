/** @format */

import React, {
    useState,
    useRef,
    useEffect,
    useMemo,
  } from "react";
import { Modal, Button, InputField } from "../../components/commons";
import closeIcon from "../../../../assets/images/close-white.svg";
import galleryApi from "../../../../apis/api/gallery";
import playlistApi from "../../../../apis/api/playlist";
import unitApi from "../../../../apis/api/unit";
import { useDispatch, useSelector } from "react-redux";
import SelectField from "../../configuration/components/selectField";
import { useForm } from "react-hook-form";
import { reqGetListUnits } from "../../../../reduxs/cms/action";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const playlistOptions = playlist => playlist.map(it => ({ id: it.id, value: it.name, name: it.name, type: it.type }));

export const availabilityOptions = [
  { value: 'available', name: 'Availability'},
  { value: 'releasing_soon', name: 'Releasing soon'},
  { value: 'sold', name: 'Sold'},
  { value: 'reserved', name: 'Reserved'},
  { value: 'multiple_offers', name: 'Multiple offers'},
  { value: 'leased', name: 'Leased' },
]

const validationSchema = yup.object().shape({
  name: yup.number().required('Name is required').typeError('Name must be a number'),
  level: yup.number().required('Level is required').typeError('Level must be a number'),
});

function capitalizeFirstLetter(string) {
  if (!string) return string; // Handle empty strings
  return string.charAt(0).toUpperCase() + string.slice(1);
}
  
const UnitEditModal = ({ setShow, unitId, show }) => {
    const { units } = useSelector((state) => state.cms);
    const [playlists, setPlaylists] = useState([]);
    const dispatch = useDispatch();

    const unit = useMemo(() => units.find(unit => unit.id === unitId) || {}, [unitId, units]);
    const getListPlaylists = async () => {
        const playlists = await playlistApi.getPlaylistList();
        setPlaylists(playlists.data);
    };

    const getListProperties = async () => {
        dispatch(
          reqGetListUnits({
            sortBy: JSON.stringify({
              name: 1,
            }),
          })
        );
      };

    const imagePlaylists = useMemo(() => playlistOptions(playlists.filter(it => it.type === 'images')), [playlists]);
    const floorplanPlaylists = useMemo(() => playlistOptions(playlists.filter(it => it.type === 'floorplans')), [playlists]);
    const viewlinePlaylists = useMemo(() => playlistOptions(playlists.filter(it => it.type === 'viewlines')), [playlists]);
    const playlist360 = useMemo(() => playlistOptions(playlists.filter(it => it.type === '360image')), [playlists]);

    useEffect(() => {
        getListPlaylists()
    }, []);

    const [galleries, setGalleries] = useState([]);
  
    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
  
      return result;
    };
  
    const onReorderGallery = async (result) => {
      if (!result.destination) {
        return;
      }
  
      const items = reorder(
        galleries,
        result.source.index,
        result.destination.index
      );
  
      const orderedGalleries = items.map((gallery, index) => ({
        ...gallery,
        order: index,
      }));
  
      setGalleries(orderedGalleries);
      await galleryApi.reorderGallery(orderedGalleries);
    };
  
    const handleClose = () => {
      setShow(false);
    };

    const { control, handleSubmit, formState } = useForm({
        defaultValues: {
            name: unit.name.split(' ')[1],
            availabilityStatus: unit.availabilityStatus ? {
              value: unit.availabilityStatus,
              name: availabilityOptions.find(it => it.value === unit.availabilityStatus).name
            } : null,
            level: unit.level,
            imagePlaylist: unit.imagePlaylist?.name ? {
                id: unit.imagePlaylist?.id,
                name: String(unit.imagePlaylist?.name),
                type: unit.imagePlaylist?.type
              } : null,
            floorplanPlaylist: unit.floorplanPlaylist?.name ? {
                id: unit.floorplanPlaylist?.id,
                name: String(unit.floorplanPlaylist?.name),
                type: unit.floorplanPlaylist?.type
              } : null,
            viewlinePlaylist: unit.viewlinePlaylist?.name ? {
                id: unit.viewlinePlaylist?.id,
                name: String(unit.viewlinePlaylist?.name),
                type: unit.viewlinePlaylist?.type
            } : null,
            "360ImagePlaylist": unit["360ImagePlaylist"]?.name ? {
                id: unit["360ImagePlaylist"]?.id,
                name: String(unit["360ImagePlaylist"]?.name),
                type: unit["360ImagePlaylist"]?.type
            } : null,
        },
        resolver: yupResolver(validationSchema)
    });

    const handleEdit = async (data) => {
        try {
            const dirtyFields = Object.keys(formState.dirtyFields);
            const body = dirtyFields.reduce((acc, cur) => {
                if (['name', 'level'].includes(cur)) {
                  acc[cur] = cur === 'name' ? 'Unit ' + data[cur] : data[cur]; 
                } else if (['availabilityStatus'].includes(cur)) {
                  acc[cur] = data[cur].value;
                } else {
                  acc[cur] = data[cur].id;
                  acc[`${cur}Override`] = playlists.find(p => p.id === data[cur].id).medias?.map(it => it.id);
                }
                return acc;
            }, {})
            const res = await unitApi.putUnitUpdate(unitId, body);
            if (res.data?.name) {
                toast.success("Update unit playlist successfully");
                return await getListProperties()
            }
            throw new Error(res.message)
        } catch (error) {
            toast.error(error.message ?? "Something's wrong");
        } finally {
            setShow(false)
        }
    };
  
    return (
      <Modal
        iconCancelMark={() => (
          <div className="z-[9999] cursor-pointer fixed top-[50px] right-[30px]">
            <img src={closeIcon} alt="close-icon" />
          </div>
        )}
        classCustom="overflow-hidden"
        wrapperClassName="wrap-update-content-modal"
        panelClassName="w-[40vw] max-w-2xl p-0 rounded-none"
        zIndex="z-[9998]"
        maskClassName="opacity-90"
        show={show}
        setShow={handleClose}
        title="Edit unit"
        content={
            <div className="bg-white rounded-lg relative">
                <div className="mb-5 flex justify-between">
                  <InputField
                    wrapperClassName="w-[49%]"
                    required
                    label="Unit number"
                    controller={{ control, name: "name" }}
                    inputProps={{ placeholder: "Please input unit number" }}
                  />
                  <InputField
                    wrapperClassName="w-[49%]"
                    required
                    label="Level"
                    controller={{ control, name: "level" }}
                    inputProps={{ placeholder: "Please input unit level" }}
                  />
                </div>
                <SelectField
                    wrapperOptionsClassName="p-[10px] rounded-[8px] border-[#000] mb-4"
                    labelClassName="text-[14px] text-[#5d5d5d] mb-[10px] font-[500]"
                    controller={{ control, name: "availabilityStatus" }}
                    required
                    label="Availability"
                    options={availabilityOptions}
                    placeholder="Please select availability status"
                />
                <SelectField
                    wrapperOptionsClassName="p-[10px] rounded-[8px] border-[#000] mb-4"
                    labelClassName="text-[14px] text-[#5d5d5d] mb-[10px] font-[500]"
                    controller={{ control, name: "imagePlaylist" }}
                    required
                    label="Gallery playlists"
                    options={imagePlaylists}
                    placeholder="Please select playlist"
                />
                <SelectField
                    wrapperOptionsClassName="p-[10px] rounded-[8px] border-[#000] mb-4"
                    labelClassName="text-[14px] text-[#5d5d5d] mb-[10px] font-[500]"
                    controller={{ control, name: "floorplanPlaylist" }}
                    required
                    label="Floorplan playlists"
                    options={floorplanPlaylists}
                    placeholder="Please select playlist"
                />
                <SelectField
                    wrapperOptionsClassName="p-[10px] rounded-[8px] border-[#000] mb-4"
                    labelClassName="text-[14px] text-[#5d5d5d] mb-[10px] font-[500]"
                    controller={{ control, name: "viewlinePlaylist" }}
                    required
                    label="Viewline playlists"
                    options={viewlinePlaylists}
                    placeholder="Please select playlist"
                />
                <SelectField
                    wrapperOptionsClassName="p-[10px] rounded-[8px] border-[#000] mb-4"
                    labelClassName="text-[14px] text-[#5d5d5d] mb-[10px] font-[500]"
                    controller={{ control, name: "360ImagePlaylist" }}
                    required
                    label="360 playlists"
                    options={playlist360}
                    placeholder="Please select playlist"
                />
                <div className="flex gap-3 mt-8">
                    <Button
                        disabled={formState?.isSubmitting}
                        variant="text"
                        onClick={() => setShow(false)}
                        className="flex-1"
                        >
                        Cancel
                    </Button>
                    <Button
                        disabled={formState?.isSubmitting || !formState?.isDirty}
                        isLoading={formState?.isSubmitting}
                        onClick={handleSubmit(handleEdit)}
                        className="bg-yellow-200 text-black hover:bg-yellow-300 hover:text-black disabled:hover:bg-yellow-200 disabled:hover:text-black flex-1"
                        >
                        Save
                    </Button>
                </div>
          </div>
        }
      />
    );
  };
  
  export default UnitEditModal;
  