import { twMerge } from 'tailwind-merge';
import cls from 'classnames';

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
};

export const cn = (...args) => {
  return twMerge(cls(...args));
};

export const handleCollapse = (target) => {
  const selector =
    target.getAttribute('data-target') || target.getAttribute('href');
  const targets = Array.from(document.querySelectorAll(selector));
  targets.forEach((target) => {
    target.classList.toggle('show');
  });
};

export const isTouchDevice = () => {
  return 'ontouchstart' in window || 'onmsgesturechange' in window;
};

export const clearFilterUnit = (detail = null) => {
  const event = new CustomEvent('UNIT_EXPLORER_PAGE', { detail });
  document.dispatchEvent(event);
};

export function numberWithCommas(x) {
  if (!x) return '';
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}


export function splitToChunksOfLengthN(array, chunkSize) {
  if (array) {
    const results = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      results.push(chunk);
    }
    return results;
  }
}

export function handleSortOrder(meidas, listOrder) {
  let data = [];
  meidas.map((i) => {
    let media = { ...i };
    const idx = (listOrder && listOrder.findIndex((o) => o === i.id)) || 0;
    media.order = idx;
    data.push(media);
  });
  return data.sort((a, b) => (a?.order < b?.order ? -1 : 1));
}

const units = ['bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

export const niceBytes = (x) => {
  let l = 0, n = parseInt(x, 10) || 0;
  while (n >= 1024 && ++l) {
    n = n / 1024;
  }
  return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
}